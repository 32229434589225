import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Pagination from 'components/pagination';
import Layout from 'components/layout';
import { Breadcrumb } from "components/ui";
import SeoComponent from 'components/seo';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const SearchPage = ({ data, location }) => {
  const { access_key, affiliate } = data.site.siteMetadata.searchgov;
  const query = new URLSearchParams(location.search).get('query');
  const page = new URLSearchParams(location.search).get('page');
  const pageNumber = page ? parseInt(page) : 1
  const results_limit = 20;
  const offset = pageNumber > 1 ? (pageNumber - 1) * results_limit : 0

  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0)

  useEffect(() => {
    const searchEndpoint = new URL(`https://api.gsa.gov/technology/searchgov/v2/results/i14y`);
    searchEndpoint.searchParams.append('affiliate', affiliate);
    searchEndpoint.searchParams.append('access_key', access_key);
    searchEndpoint.searchParams.append('query', query);
    searchEndpoint.searchParams.append('limit', results_limit);
    searchEndpoint.searchParams.append('offset', offset );


    fetch(searchEndpoint)
      .then(r => {
        if (r.ok) {
          return r.json();
        }
        throw new Error(r.statusText);
      })
      .then(posts => {
        setResults(posts.web.results);
        setTotalResults(posts.web.total)
      })
      .catch(err => console.log(err));
  }, [query, page, access_key, affiliate]);

  const totalPages = Math.ceil(totalResults / results_limit)

  const pageContext = {
    humanPageNumber: pageNumber,
    numberOfPages: totalPages,
    previousPagePath: `/search?query=${query?.replaceAll(/ /g,"+")}&page=${pageNumber - 1}`,
    nextPagePath: `/search?query=${query?.replaceAll(/ /g,"+")}&page=${pageNumber+1}`
  }

  let thisLocationPathname = ''

  if (isBrowser) {
      thisLocationPathname = window.location.pathname
  }

  return (
    <Layout location={location}>
      <SeoComponent title="Search" description="Search the results of NCSACW's literature review of books, journal articles, and reports related to child welfare, substance use disorders, legal, safety, risk, co-occurring disorders, drug testing, screening, assessment, engagement, family-centered, fetal alcohol spectrum disorder, FASD, evidence-based practice, medication-assisted treatment, MAT, opioids, plan of safe care, and trauma-informed care." keywords=""/>
      <div className="usa-layout-docs usa-section padding-top-0">
        <div className="grid-container">
          <div className="grid-row grid-gap">
            <div className="usa-layout-docs__main desktop:grid-col-12">
            <Breadcrumb pathname={thisLocationPathname} customCurrentPage={`Search`}/>
              <h1 className="margin-bottom-0" id="main-content" name="main-content">You searched for <em>"{query}"</em></h1>
              <h2 className="margin-top-0">Search found {totalResults} results.</h2>

              {results.length > 0 ? (
                <>
                <ol className="add-list-reset">
                  {results.map((r, idx) => (
                    <li
                      key={idx}
                      className="padding-bottom-5 margin-top-4 border-bottom-05 border-base-lightest"
                    >
                      <h4>
                        <a href={r.url}
                          dangerouslySetInnerHTML={{
                            __html: r.title
                              .replace(/\uE000/g, '<span class="bg-yellow">')
                              .replace(/\uE001/g, '</span>'),
                          }}
                        >
                        </a>
                      </h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: r.snippet
                            .replace(/\uE000/g, '<span class="bg-yellow">')
                            .replace(/\uE001/g, '</span>'),
                        }}
                      />
                    </li>
                  ))}
                </ol>

                {/* Pagination links */}
                <Pagination collectionPath={`/search?query=${query.replaceAll(/ /g,"+")}`} pageContext={pageContext} totalResults={totalResults}/>
                </>
              ) : (
                <h4 className="title">
                  No results found for search term: {query}
                </h4>
              )}

              {affiliate === 'federalist-uswds-example' && (
                <div className="usa-alert usa-alert--info">
                  <div className="usa-alert__body">
                    This is an example. You will need to configure your site
                    with search.gov to see the correct search results. To do
                    this:
                    <ol>
                      <li>
                        Create an account with Search.gov at{' '}
                        <a href="https://search.usa.gov/signup">
                          https://search.usa.gov/signup
                        </a>
                      </li>
                      <li>
                        Go to the "Activate" section and get "API Access Key"
                      </li>
                      <li>
                        Open <code>gatsby-config.js</code> and look for{' '}
                        <code>searchgov</code> fields
                      </li>
                      <li>
                        Add your <code>affiliate</code> and{' '}
                        <code>access_key</code>
                      </li>
                      <li>
                        Your results will not show up immediately. Make sure you
                        follow{' '}
                        <a href="https://search.gov/manual/searchgov-for-federalist.html">
                          instructions to index your site
                        </a>
                        .
                      </li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        searchgov {
          access_key
          affiliate
          endpoint
          inline
        }
      }
    }
  }
`;

export default SearchPage;
